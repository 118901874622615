.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.app__profile-item {
    position: relative;
    width: 190px;
    display: flex;
    justify-content: flex-start;
    // align-items: flex-start;
    flex-direction: column;
    margin: 2rem;


    .img-container{
       

        :hover {
            border: 1px solid white;
            // padding:30px;
            box-shadow: inset 0px 0px 2px #fff,
              0px 0px 2px #fff,
              0 0 5px #28d7fe,
              inset 0 0 5px #28d7fe,
              0 0 10px 1px #28d7fe,
              inset 0 0 10px  1px #28d7fe;
              transition: all 0.3s ease-in-out;

    }
    } 

    // .img-container:hover{
    //     opacity:1;
    //     transition: all 2.4s ease-in-out;
    // }
   
    
  

    img {
        // position: relative;
        width: 100%;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;
        border: 1px solid var(--secondary-color); 
        transition: all 0.3s ease-in-out;
     
    }

   

    @media screen and (min-width: 2000px) {
        width: 370px;
        margin: 2rem 4rem;

        img {
            height: 320px;
        }
    }


}



