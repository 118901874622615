.app {
    background-color: var(--primary-color);
    font-family: var(--font-base);
    overflow: hidden;
  }
  
  .app__whitebg {
    background-color: var(--white-color);
    background-color:  var(--primary-color);
  }
  
  .app__primarybg {
    background-color: var(--primary-color);
  }
  
  .app__container {
    width: 100%;
    min-height: 100vh;
  
    display: flex;
    flex-direction: row;
  }
  
  .app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 4rem 2rem;

    @media screen and (max-width: 650px) {
      padding: 4rem 1rem;
    }
  
    @media screen and (max-width: 450px) {
      padding: 4rem 1rem 2rem;
    }

  }
  
  .copyright {
    width: 100%;
    padding: 2rem 0 0;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  
    p {
      text-transform: uppercase;
      color: var(--lightBlue-color);
    }
  }
  
  .head-text {
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--lightBlue-color);
  
    text-transform: capitalize;
  
    span {
      color: var(--lightBlue-color);
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 4rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 320px) {
      font-size: 1.6rem;
    }
  }
  
  .p-text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--secondary-color);
    line-height: 1.5;
    
    @media screen and (max-width: 390px) {
      font-size: 0.7rem;    
    }
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem;
    }
  }
  
  .bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--secondary-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }
  
  .app__social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    padding: 0.5rem;

        @media screen and (max-width: 550px) {
          padding: 0.2rem;
        }
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--white-color);
      background-color: transparent;
      margin: 0.25rem 0;
      border: 1px solid var(--secondary-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 550px) {
        width: 30px;
        height: 30px;
      }
  
      svg {
        display: flex;
        justify-content: center;
        width: 15px;
        height: 15px;
        color: var(--secondary-color);
        transition: all 0.3s ease-in-out;

        @media screen and (max-width: 550px) {
          width: 12px;
          height: 12px;
        }
      }
  
      &:hover {
        background-color: transparent;
        border-color: var(--secondary-color);
        box-shadow: inset 0px 0px 2px #fff,
        0px 0px 2px #fff,
        0 0 5px #28d7fe,
        inset 0 0 5px #28d7fe,
        0 0 10px 1px #28d7fe,
        inset 0 0 10px  1px #28d7fe;
  
        svg {
          color: var(--white-color);
          color: var(--lightBlue-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }



  .app__social.navbar-style {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  
    padding: 0.5rem;

        @media screen and (max-width: 490px) {
          display: flex;
          padding: 0.2rem;
        }
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--white-color);
      background-color: transparent;
      margin: 0.25rem 0;
      border: 1px solid var(--secondary-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 550px) {
        margin: 0.2rem;
        width: 30px;
        height: 30px;
      }
  
      svg {
        display: flex;
        justify-content: center;
        width: 15px;
        height: 15px;
        color: var(--secondary-color);
        transition: all 0.3s ease-in-out;

        @media screen and (max-width: 550px) {
          width: 12px;
          height: 12px;
        }
      }
  
      &:hover {
        background-color: transparent;
        border-color: var(--secondary-color);
        box-shadow: inset 0px 0px 2px #fff,
        0px 0px 2px #fff,
        0 0 5px #28d7fe,
        inset 0 0 5px #28d7fe,
        0 0 10px 1px #28d7fe,
        inset 0 0 10px  1px #28d7fe;
  
        svg {
          color: var(--white-color);
          color: var(--lightBlue-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }


  
  .app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    padding: 1rem;

        @media screen and (max-width: 550px) {
        padding: 0.8rem;
        }
  
    .app__navigation-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      // background-color: #cbcbcb;
      background-color:var(--secondary-color);
  
      // margin: 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
  
      transition: background-color 0.2s ease-in-out;
      
  
     
  
      &:hover {
        background-color: var(--lightBlue-color);
      }

    


      @media screen and (max-width: 500px) {
        margin: revert;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        }
  
      @media screen and (min-width: 2000px) {
        width: 20px;
        height: 20px;


      //   ::before, ::after{
      //     content: '';
      //     width:55px;
      //     border-radius: 55px;
      //     -moz-border-radius: 55px;
      //     -webkit-border-radius: 55px;
      //     background-color:#ffffff;
      //  }
      }
    }
  }
  
  @media screen and (max-width: 500px) {
    .app__navigation,
    .app__social {
      display: none;
    }
     }

     

     @media screen and (max-width: 450px) {
      .hire {
        font-size: 2rem!important;
      }
      }

      @media screen and (max-width: 320px) {
        .hire {
          font-size: 1.4rem!important;
        }
        }
  
  //   .copyright {
  //     padding: 2rem;
  //   }
  // }

  // .ml-10 {
  //   margin-left: 10px;
  // }

  body {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

body::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}